import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AddressListItem } from '@mp/organization-master-data/addresses/domain';
import { PageResponse } from '@mp/shared/data-access';

export const AddressesActions = createActionGroup({
  source: 'Organization Master Data - Addresses',
  events: {
    fetchAddresses: props<{ searchTerm?: string }>(),
    fetchAddressesSuccess: props<{ addressesPageResponse: PageResponse<AddressListItem> }>(),
    fetchAddressesError: emptyProps(),

    upsertAddressToList: props<{ address: AddressListItem }>(),
  },
});
