import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs';

import { AddressListItem } from '@mp/organization-master-data/addresses/domain';
import { NotificationService, PageResponse } from '@mp/shared/data-access';

import { AddressesService } from '../services';

import { AddressesActions } from './addresses.actions';

@Injectable()
export class AddressesEffects {
  fetchAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddressesActions.fetchAddresses),
      fetch({
        run: ({ searchTerm }) =>
          this.addressesService
            .fetchAddresses(searchTerm)
            .pipe(
              map((addressesPageResponse: PageResponse<AddressListItem>) =>
                AddressesActions.fetchAddressesSuccess({ addressesPageResponse }),
              ),
            ),
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Laden der Adressen.');
          return AddressesActions.fetchAddressesError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly addressesService: AddressesService,
    private readonly notificationService: NotificationService,
  ) {}
}
